 <template>
  <b-modal class="bmodal" size="sm" id="addMeetingLocationVirtualModal" ref="modalAddMeetingLocationVirtual"
    :title="i18n.components && i18n.components['add-meeting-location-virtual'] && i18n.components['add-meeting-location-virtual'].tcAddVirtualLocation"
    centered @ok="addNewLocationVirtual($event)" @cancel="handleCancelClick()" @show="handleShow()"
    :ok-title="i18n.tcOk" :cancel-title="i18n.tcCancel" :ok-disabled="!validData">
    <div>
      <form ref="form">
        <b-container fluid class="text-light text-left">
          <div class="pt-5">
            <b-form>
              <b-form-group class="form-element">
                <b-form-input id="name-input"
                  :placeholder="i18n.components && i18n.components['add-meeting-location-virtual'] && i18n.components['add-meeting-location-virtual'].tcName"
                  v-model="form.lvt_name" :state="form.lvt_name !== null && form.lvt_name !== ''" required>
                </b-form-input>
              </b-form-group>
              <b-form-group class="form-element">
                <b-form-input id="url-input"
                  :placeholder="i18n.components && i18n.components['add-meeting-location-virtual'] && i18n.components['add-meeting-location-virtual'].tcUrl"
                  v-model="form.lvt_url" :state="form.lvt_url !== null && form.lvt_url !== ''" required></b-form-input>
              </b-form-group>
              <b-form-group class="form-element">
                <b-form-textarea id="note-input" v-model="form.lvt_note"
                  :placeholder="i18n.components && i18n.components['add-meeting-location-virtual'] && i18n.components['add-meeting-location-virtual'].tcNote"
                  rows="3" max-rows="6"></b-form-textarea>
              </b-form-group>
              <b-form-datepicker id="exipration-date-input" v-model="form.lvt_expiration_date" class="mb-2 contact_date"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                :locale="prefCulture" :label-help="i18n.tcCalHelp"
                :label-no-date-selected="i18n.tcDatePickerPlaceholder"
                :placeholder="i18n.components && i18n.components['add-meeting-location-virtual'] && i18n.components['add-meeting-location-virtual'].tcExpirationDate"
                size="sm" :min="min" :show-decade-nav="showDecadeNav" :hide-header="hideHeader">
                <template #button-content style="padding: 12px">
                  <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                </template>
              </b-form-datepicker>
            </b-form>
          </div>
        </b-container>
      </form>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters, mapState } from 'vuex'
import calendarService from '../services/calendarService'
import { Loader } from '@/mixins/Loader'

export default {
  name: 'add-meeting-location-virtual',
  mixins: [Loader],
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          components: {
            'add-meeting-location-virtual': {
              tcAddLocation: 'Add Virtual Location',
              tcCancel: 'Cancel',
              tcOk: 'Ok',
              tcOperationWasSuccessful: 'Operation was successful',
              tcName: 'Name',
              tcUrl: 'Url',
              tcNote: 'Note',
              tcExpirationDate: 'Expiration Date',
            },
          },
        }
      },
    },
  },
  data() {
    return {
      country_us: 'bc4b70f8-280e-4bb0-b935-9f728c50e183',
      form: {
        lvt_name: null,
        lvt_url: null,
        lvt_note: null,
        lvt_expiration_date: null,
      },
      hideHeader: true,
      isDisabled: true,
      min: null,
      modalTitle: 'Add Virtual Location',
      originalData: null,
      result: {},
      showDecadeNav: true,
      state: null,
    }
  },
  methods: {
    ...mapActions({
    }),
    async addNewLocationVirtual() {
      if (this.validData) {
        let postLocation = {
          lvt_key: null,
          lvt_add_user: this.user.userLogin.indkey,
          lvt_user_ind_key: this.user.demograph.ind_key,
          lvt_user_camp_key: this.user.demograph.camp_key,
          lvt_name: this.form.lvt_name,
          lvt_url: this.form.lvt_url,
          lvt_note: this.form.lvt_note,
          lvt_expiration_date: this.form.lvt_expiration_date,
          lvt_delete_flag: false,
          lvt_nag_key: null
        }
        this.result = {}
        this.showLoader()
        await calendarService
          .UpsertVirtualLocation(postLocation)
          .then((res) => {
            if (res.status === 200) {
              this.$emit('locationVirtualSelectionMade', { key: res.data, name: postLocation.lvt_name })
              this.message(
                this.i18n.components['add-meeting-location-virtual'].tcOperationWasSuccessful,
                'success',
                this.i18n.tcOk
              )
              this.closeLoader()
            }
          })
          .catch((err) => {
            this.message()
            this.closeLoader()
          })
      } else {
        return
      }
    },
    handleCancelClick() {
      this.clearForm()
    },
    async handleShow() {
      this.clearForm()
    },
    closeModal() {
      this.clearForm()
      this.$refs.modalAddMeetingLocationVirtual.hide()
    },
    clearForm() {
      this.form.lvt_name = null
      this.form.lvt_url = null
      this.form.lvt_note = null
      this.form.lvt_expiration_date = null
    },
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
    validData() {
      return (
        this.form.lvt_name &&
        this.form.lvt_name.length > 0 &&
        this.form.lvt_url &&
        this.form.lvt_url.length > 0
      )
    },
  },
  watch: {
    form: {
      handler(netval, oldva) {
        this.isDisabled = Object.keys(this.form).some((item) => {
          return this.form[item] === null || this.form[item] === ''
        })
      },
    },
  },
  async created() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
    this.min = new Date(today)
  },
}
</script>

<style>
</style>