<template>
  <div>
    <b-button @click="openModal">{{ i18n['location-virtual-search'].tcFindVirtualLocation }}</b-button>
    <b-modal
      ref="locationVirtualModal"
      class="bmodal"
      size="sm"
      :title="i18n['location-virtual-search'].tcFindVirtualLocation"
      button-size="sm"
      :hide-footer="showResults && !!searchResults.length"
      @ok="runSearch"
      @cancel="closeModal"
      :ok-title="i18n['location-virtual-search'].tcOk"
      :cancel-title="i18n['location-virtual-search'].tcCancel"
      :ok-disabled="!form.name"
    >
      <b-form v-if="!showResults || !searchResults.length" @submit.prevent="runSearch">
        <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ i18n['location-virtual-search'].tcName }}
            <b-form-input v-model="form.name" />
          </b-col>
        </b-row>
        <!-- <b-row class="lh42">
          <b-col sm="12" class="font-style-3">
            {{ i18n['location-virtual-search'].tcCity }}
            <b-form-input v-model="form.city" />
          </b-col>
        </b-row> -->
      </b-form>
      <div class="noresults" v-if="showResults && !searchResults.length">
        {{ i18n['location-virtual-search'].tcNoMatchesWereFound }}
      </div>
      <div v-if="showResults && searchResults.length">
        <b-button class="btn-tertiary btn-w-sm float-right" @click="showResults = false"> {{ i18n['location-virtual-search'].tcBack }} </b-button>
        <h5 v-if="showResults && !!searchResults.length">
          {{ i18n['location-virtual-search'].tcResults }}
          <br />{{ i18n['location-virtual-search'].tcClickToSelect }}
        </h5>
        <div class="long_list">
          <ul>
            <li v-for="location in searchResults" :key="location.lvt_key">
              <div class="resultDiv" @click="selectLocation(location)">
                <b>{{ location.lvt_name }}</b>
                <div>{{ location.lvt_url }}</div>
                <div>{{ location.lvt_note }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import { CALENDAR_API_CONFIG } from '@/config/calendar.api.config'

export default {
  name: 'location-search',
  props: {
    cnt_key: {
      type: String,
      default: 'BC4B70F8-280E-4BB0-B935-9F728C50E183',
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'location-virtual-search': {
            tcBack: 'Back',
            tcCancel: 'Cancel',
            tcCity: 'City',
            tcClickToSelect: 'Click to Select',
            tcFindLocation: 'Find Location',
            tcName: 'Name',
            tcNoMatchesWereFound: 'No matches were found',
            tcOk: 'Ok',
            tcResults: 'Results',
          },
        }
      }
    }
  },
  data() {
    return {
      form: {
        name: null,
        //city: null,
      },
      searchResults: [],
      showResults: false,
    }
  },
  methods: {
    ...mapActions({ setLoadingStatus: 'menu/setLoadingStatus' }),
    openModal() {
      this.$refs.locationVirtualModal.show()
    },
    closeModal() {
      this.form = { name: null, city: null }
      this.showResults = false
      this.searchResults = []
    },
    async runSearch(modalEvent) {
      modalEvent.preventDefault()
      try {
        this.setLoadingStatus(true)
        const { name, city } = this.form
        const url = CALENDAR_API_CONFIG.searchEventLocationVirtual(name, this.demograph.camp_key)
        try {
          const { data } = await axios.get(url)
          this.searchResults = data
          this.showResults = true
        } catch (error) {
          throw error
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    selectLocation(location) {
      this.$emit('location-virtual-select', location)
      this.$refs.locationVirtualModal.hide()
    },
  },
  computed: {
    ...mapGetters({
      demograph: 'user/demograph',
    }),
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
.noresults {
  color: #dd0000;
  font-weight: 600;
}
.resultDiv {
  cursor: pointer;
}
.resultDiv:hover {
  background-color: gainsboro;
}
.lh42 {
  margin-bottom: 6px;
}
.long_list {
  margin: 20px 8px;
  max-height: 398px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.long_list ul li {
  font-size: 13px;
  margin-bottom: 10px;
}
.font-style-3 {
  font-weight: 600;
  text-transform: uppercase;
}
</style>
